import { __extends } from "tslib";
import { ReplayEventPubSub } from './replayeventpubsub';
var EventPubSub = (function (_super) {
    __extends(EventPubSub, _super);
    function EventPubSub(eventName, shouldResolveInitialValueOnSubscribe, $q, _) {
        return _super.call(this, eventName, shouldResolveInitialValueOnSubscribe, $q, _) || this;
    }
    return EventPubSub;
}(ReplayEventPubSub));
export { EventPubSub };
