'use strict';

import { EventPubSub, HubRegistrations } from 'isc-ui/dist/event/eventpubsub';
import { AuthService } from '../dataservices/auth.service';
import { ForumService } from '../dataservices/forum.service';
import _ = require('lodash');
import * as signalR from '@microsoft/signalr';
import { ServerConstants } from '../serverconstants';
import { Utils } from '../utils';

export abstract class NotificationsServiceBase {
  // #region Events
  readonly connected: EventPubSub<boolean>;
  private pendingCalls: Array<() => void> = [];
  private connection: signalR.HubConnection;
  private static connectionId: string;
  protected disconnect = _.noop;
  private groups: string[] = [];
  public isConnected = false;

  private readonly hubRegistrations: HubRegistrations = [];

  constructor(
    protected logger: Logger,
    protected $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
    private __env,
    protected authService: AuthService,
    private forumservice: ForumService,
    protected serverConstants: ServerConstants,
  ) {
    this.connected = new EventPubSub<boolean>('SignalrConnected', false, $q, _);
  }

  protected createEvent<T>(signalrEvent: number, shouldResolveInitialValueOnSubscribe = true): EventPubSub<T> {
    const pubSub = new EventPubSub<T>(Utils.getEnumValueName(this.serverConstants.realtimeMessagingConstants, signalrEvent, false),
      shouldResolveInitialValueOnSubscribe, this.$q, _);
    this.hubRegistrations.push(pubSub as EventPubSub<unknown>);
    return pubSub;
  }
  // #endregion

  // #region Connection
  protected async connect() {
    this.onLogout();

    let qs = `?version=${this.__env.buildVersion}`;
    if (this.authService.impersonate?.SquareParticipantGuid) {
      qs = `${qs}&ImpersonateGuid=${this.authService.impersonate.SquareParticipantGuid}`;
    }
    const signalrToken = await this.getSignalRToken();

    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(`${this.__env.signalrUrl}/realtime${qs}`, {
        accessTokenFactory: async () => signalrToken,
      })
      .build();

    const unsubscribeFromTokenChange = this.addOnTokenChangeListener(async () => {
      await this.connect();
    });

    let connectInternal = async () => {
      await this.connection.start();
      NotificationsServiceBase.connectionId = this.connection.connectionId;
      this.connected.next(true);
      _.forEach(this.hubRegistrations, (reg) => {
        reg.useConnection(this.connection);
        reg.resetSubscriptions();
      });

      // Restore groups
      for (const groupName of this.groups) {
        this.addUserToGroup(groupName);
      }

      for (const pending of this.pendingCalls) {
        pending();
      }
      this.pendingCalls = [];
      this.isConnected = true;
    };
    await connectInternal();

    this.disconnect = () => {
      this.isConnected = false;
      _.forEach(this.hubRegistrations, (reg) => this.connection.off(reg.eventName));
      unsubscribeFromTokenChange();
      this.pendingCalls = [];
      this.connection.stop();
      connectInternal = () => Promise.resolve();
      this.disconnect = _.noop;
      NotificationsServiceBase.connectionId = null;
      this.connected.next(false);
    };
  }

  static get ConnectionId() {
    return NotificationsServiceBase.connectionId;
  }
  // #endregion

  // #region Event handling
  protected forwardCallToHub(method: string, ...args) {
    const signalrCall = () => {
      this.connection?.invoke(method, ...args);
    };

    // If the connection is down, keep the call in a queue
    // Unfortunately we loose the exact timestamp
    if (this.connection?.state !== signalR.HubConnectionState.Connected) {
      this.pendingCalls.push(signalrCall);
    } else {
      signalrCall();
    }
  }
  // #endregion

  // #region Groups
  public async addUserToGroup(groupName: string) {
    if (groupName === undefined || groupName === null) {
      return;
    }
    const forwardCall = () => {
      this.forwardCallToHub('addUserToGroup', groupName);
      if (!_.includes(this.groups, groupName)) {
        this.groups.push(groupName);
      }
    };

    if (!NotificationsServiceBase.connectionId) {
      this.pendingCalls.push(forwardCall);
    } else {
      forwardCall();
    }

    await this.forumservice.addUserToGroup(groupName);
  }

  public async removeUserFromGroup(groupName: string) {
    const forwardCall = () => {
      this.forwardCallToHub('removeUserFromGroup', groupName);
      _.remove(this.groups, (item) => item === groupName);
    };

    if (!NotificationsServiceBase.connectionId) {
      this.pendingCalls.push(forwardCall);
    } else {
      forwardCall();
    }

    await this.forumservice.removeUserFromGroup(groupName);
  }

  // #endregion

  protected abstract getSignalRToken(): ng.IPromise<string>;
  protected abstract addOnTokenChangeListener(fn: () => void): () => void;

  protected onLogout() {
    this.disconnect();
  }
}
