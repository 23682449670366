var ReplayEventPubSub = (function () {
    function ReplayEventPubSub(eventName, shouldResolveInitialValueOnSubscribe, $q, _) {
        if (shouldResolveInitialValueOnSubscribe === void 0) { shouldResolveInitialValueOnSubscribe = true; }
        this.eventName = eventName;
        this.shouldResolveInitialValueOnSubscribe = shouldResolveInitialValueOnSubscribe;
        this.$q = $q;
        this._ = _;
        this.subscriptionsCount = 0;
        this.shouldMapOnInitialResolve = true;
    }
    ReplayEventPubSub.prototype.startWith = function (promiseFactoryOrValue) {
        this.initialValue = promiseFactoryOrValue;
        return this;
    };
    ReplayEventPubSub.prototype.subscribe = function (callback) {
        var _this = this;
        var _a;
        (_a = this.connection) === null || _a === void 0 ? void 0 : _a.on(this.eventName, callback);
        var unsubscribeFromScope = function () { var _a; return (_a = _this.connection) === null || _a === void 0 ? void 0 : _a.off(_this.eventName, callback); };
        this.subscriptionsCount++;
        if (this.shouldResolveInitialValueOnSubscribe) {
            this.resolveInitialValue();
        }
        return function () {
            _this.subscriptionsCount--;
            unsubscribeFromScope();
        };
    };
    ReplayEventPubSub.prototype.useConnection = function (connection) {
        this.connection = connection;
    };
    ReplayEventPubSub.prototype.resetSubscriptions = function () {
        this.lastValue = undefined;
        if (this.subscriptionsCount > 0 && this.shouldResolveInitialValueOnSubscribe) {
            this.resolveInitialValue();
        }
    };
    ReplayEventPubSub.prototype.next = function (data, isInitialResolve) {
        var _a;
        if (isInitialResolve === void 0) { isInitialResolve = false; }
        if (this.lastValue !== data || (this.lastValue === undefined && data === undefined)) {
            if (this.mapping && (!isInitialResolve || this.shouldMapOnInitialResolve)) {
                data = data.map(this.mapping);
            }
            this.lastValue = data;
            (_a = this.connection) === null || _a === void 0 ? void 0 : _a.invoke(this.eventName, data);
        }
    };
    ReplayEventPubSub.prototype.resolveInitialValue = function () {
        var _this = this;
        if (this.lastValue !== void 0) {
            this.next(this.lastValue, true);
        }
        else if (this.initialValue !== void 0) {
            var init = this.initialValue;
            if (this._.isFunction(init)) {
                init = init();
            }
            this.$q.resolve(init).then(function (data) {
                _this.next(data, true);
            });
        }
    };
    ReplayEventPubSub.prototype.toPromise = function () {
        var _this = this;
        if (this.lastValue !== void 0 || this.initialValue !== void 0) {
            return this.$q.resolve((this.lastValue || this.initialValue));
        }
        return this.$q(function (resolve) {
            var unsubscribe = _this.subscribe(_this._.once(function (data) {
                _this.$q.resolve(data).then(function (d) {
                    unsubscribe();
                    resolve(d);
                });
            }));
        });
    };
    ReplayEventPubSub.prototype.withMapping = function (mapping, shouldMapOnInitialResolve) {
        this.mapping = mapping;
        this.shouldMapOnInitialResolve = shouldMapOnInitialResolve;
        return this;
    };
    return ReplayEventPubSub;
}());
export { ReplayEventPubSub };
